/*--- footer ---*/
.int-page-footer {
  background-color: #222;
  color: #ccc;
  padding: 60px 0 30px;
  position: absolute;
  left: 0;
  right: 0;
}
.int-footer-copyright {
  color: #666;
  padding: 0px 0;
}
